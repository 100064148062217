<template>
  <div
    class="tab-pane fade"
    id="pills-UploadGUID"
    role="tabpanel"
    aria-labelledby="pills-UploadGUID-tab"
  >
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="dash-box">
        <label for="guid-upload" class="btn-black">Choose CSV file</label>
        <p class="tbody-child">{{ " " }}{{ file.name }}</p>
        <input id="guid-upload" type="file" @change="uploadFileChange" />
      </div>

      <div class="hid-box">
        <button
          @click="uploadGuidFile"
          :disabled="!excel_file.length"
          :class="!excel_file.length ? 'disable-btn' : 'btn-black'"
        >
          {{ processing ? "Please wait..." : "Upload Template" }}
        </button>
      </div>
    </div>

    <div class="ma-table">
      <div class="ma-thead">
        <div v-for="(text, i) in thead_texts" :key="i" class="thead-child">
          {{ text }}
        </div>
      </div>

      <hr style="border: 0.6px solid #e5e5e5" />
      <div v-if="allSuccessfulUploadedData.length > 0">
        <div v-for="(item, i) in allSuccessfulUploadedData" :key="i">
          <div class="ma-tbody">
            <div class="tbody-child">
              {{ addCommas(item.amount) }}
            </div>
            <div
              class="tbody-child"
              style="padding-right: 40px; word-break: break-word"
            >
              <div class="text-success" v-if="item.status">True</div>
              <div v-else class="text-danger">False</div>
            </div>
            <div class="tbody-child" style="word-break: break-word">
              {{ item.remark.replace(/_/g, " ") }}
            </div>
          </div>

          <hr style="border: 0.6px solid #e5e5e5" />
        </div>
      </div>

      <div v-else-if="setLoading">
        <div class="d-flex align-items-center justify-content-center p5">
          <div
            class="spinner-border"
            style="width: 3rem; height: 3rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text--black text--600 text--medium d-inline-flex ml-4"
            >Please wait</span
          >
        </div>
      </div>

      <div v-else>
        <div
          class="d-flex flex-column align-items-center justify-content-center p5"
        >
          <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

          <span class="d-block text--danger text--600 text--medium mt-3 mb-5">
            No uploaded data available</span
          >
        </div>
      </div>
    </div>

    <!-- <DeleteModal
      :show="showDeletedMappedAccModal"
      @close="closeDeletedMappedAccount"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unmap Account</h5>
            <p>Please confirm that you want to unmap this account</p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--md modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeletedMappedAccount"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="loadingUnMap"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes modal__confirm__button--delete fitrem"
                @click="unMapAccount"
                v-else
              >
                confirm
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import * as XLSX from "xlsx";
// import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import { SEND_GUID_UPLOADED_DATA } from "@/core/services/store/coreBanking.module";

export default {
  name: "UploadGUID",
  data() {
    return {
      thead_texts: ["Amount", "Status", "Remark"],
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
      file: "",
      excel_file: [],
      processing: false,
    };
  },
  components: {
    // DeleteModal,
  },
  methods: {
    uploadGuidFile() {
      if (this.excel_file.length) {
        this.processing = true;
        let formData = { request_keys: this.excel_file };
        this.$store.dispatch(SEND_GUID_UPLOADED_DATA, formData).then(() => {
          this.alertSuccess("Upload Successful.");
          this.processing = false;
          this.excel_file = [];
          this.file = "";
          this.$emit("accountMapped");
        });
      } else {
        this.alertError("No file was added for upload");
      }
    },

    uploadFileChange(event) {
      this.file = event.target.files ? event.target.files[0] : "";

      if (this.file) {
        this.$store.dispatch(SEND_GUID_UPLOADED_DATA, { request_keys: [] });
        const reader = new FileReader();
        let self = this;

        reader.onload = function (e) {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          /* DO SOMETHING WITH workbook HERE */
          let worksheet = workbook.Sheets[sheetName];
          let arr_data = XLSX.utils.sheet_to_json(worksheet);
          arr_data.type = Array;

          for (let i = 0; i < arr_data.length; i++) {
            self.excel_file.push(arr_data[i].Request_ID);
          }
        };
        reader.readAsArrayBuffer(this.file);
      }
    },

    addCommas(amount) {
      let currency = `\u20A6`;
      if (amount) {
        let fixedAmount = amount.toFixed(2);
        return `${currency}${fixedAmount
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
      } else {
        return `${currency}0.00`;
      }
    },

    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        this.parameters.pageindex++;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      } else if (pages === "previous") {
        this.parameters.pageindex--;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      } else if (pages !== null) {
        this.parameters.pageindex = pages;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      }
    },

    changeLength(value) {
      this.parameters.pagesize = value;
      this.$emit("getTransData", this.parameters);
    },

    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  watch: {
    errorUploading() {
      if (this.errorUploading) return this.alertError(this.errorUploading);
    },
  },
  computed: {
    setLoading() {
      return this.loading;
    },
    ...mapState({
      allSuccessfulUploadedData: (state) => {
        return state.coreBankingModule.allGuidData;
      },
      errorUploading: (state) => {
        return state.coreBankingModule.uploadGuidError;
      },
    }),
  },
};
</script>

<style scoped>
.head-text {
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "Montserrat-semibold";
  line-height: 32px;
  color: #1e1f20;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 40px;
  background: #e9e7e7;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
.btn-black {
  width: 185px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.dash-box {
  width: 70%;
  /* border: 1px dashed #c4c4c4; */
  box-sizing: border-box;
  height: 60px;
  display: flex;
  align-items: center;
}
.hid-box {
  width: 30%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.thead-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9596a2;
  width: 33.3%;
  text-align: flex-start;
}
.tbody-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  width: 33.3%;
  text-align: flex-start;
  /* padding: 10px 40px 10px 0; */
}
.ma-thead,
.ma-tbody {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.ma-table {
  display: flex;
  flex-direction: column;
}
.action {
  width: 95px;
  height: 35px;
  background: #e9e7e7;
  border-radius: 5px;
  border: 1px dashed #e9e7e7;
  font-weight: 500;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.action-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.action-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.disable-btn {
  background: #e9e7e7;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e9e7e7;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
input[type="file"] {
  display: none;
}
</style>
